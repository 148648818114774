import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Layout, Container, Title, Listing } from '../components'
import nicer from '../utils/nicer'

export default function Projects({ data }) {
  const projects = nicer(data.projects, { category: true, blurb: true })
  return (
    <Layout logo>
      <Container main>
        <Title>Projects</Title>
        <Listing items={projects} mojy spaced />
      </Container>
    </Layout>
  )
}

Projects.propTypes = {
  data: PropTypes.object.isRequired
}

export const pageQuery = graphql`
  query {
    projects: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { kind: { eq: "projects" } } }
    ) {
      edges {
        node {
          id
          html
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            category
            link
          }
        }
      }
    }
  }
`
